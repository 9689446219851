/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image'
import { jsx, Flex, useThemeUI, Box } from 'theme-ui'
import { useWindowSize } from 'react-use'
import ImageAndTextSection from '~/components/Product/Page/ImageAndTextSection'
import PressCopyAndQuotes from '~/components/Home/PressSection/PressCopyAndQuotes'

function PressSection({
  page: {
    heading,
    subheading,
    heroImage,
    heroCallToAction,
    localButtonStyle,
    pageSections,
  },
}) {
  const { theme } = useThemeUI()
  const pressSection = pageSections.find(section => section.slug === 'press')
  const { images } = pressSection
  const { width } = useWindowSize()

  const shellNoodleImg = images.find(i => i.title === 'shell-noodle-optimized')
  const macNoodleImg = images.find(
    i => i.title === 'macaroni-noodle-2-optimized',
  )

  // TODO
  // 1. (optional)? Animate quote change
  //   ?? Questions
  // Are nood images hi res enough?

  return (
    <Flex
      sx={{
        backgroundColor: 'schoolBusYellow',
        alignItems: 'center',
        color: theme.colors.brilliantRose,
        justifyContent: 'center',
        position: 'relative',
        pt: ['0px ', '65px'],
        pb: ['45px', '96px'],
        width: '100vw',
        flexDirection: ['column', 'row'],
      }}
    >
      {/* Shell Noodle 1 */}
      <Box
        sx={{
          position: 'absolute',
          right: ['-25px', '109px'],
          top: ['360px', '-60px'],
        }}
      >
        <GatsbyImage
          alt=""
          image={shellNoodleImg.gatsbyImageData}
          sx={{ width: ['58px', '84px'], height: ['58px', '84px'] }}
        />
      </Box>
      {/* Shell Noodle 2 (Only Desktop)*/}
      <Box
        sx={{
          position: 'absolute',
          left: '-48px',
          top: '-90px',
          display: ['none', 'block'],
        }}
      >
        <GatsbyImage
          alt=""
          image={shellNoodleImg.gatsbyImageData}
          sx={{ width: '122px', height: '122px', transform: 'rotate(100deg)' }}
        />
      </Box>
      {/* Shell Noodle 3 */}
      <Box
        sx={{
          position: 'absolute',
          right: ['320px', width < 1090 ? '-32px' : '-8px'],
          left: ['20px', 'initial'],
          top: ['490px', '90px'],
        }}
      >
        <GatsbyImage
          alt=""
          image={shellNoodleImg.gatsbyImageData}
          sx={{
            width: ['46px', '86px', '122px'],
            height: ['46px', '86px', '122px'],
            transform: ['rotate(100deg)', 'rotate(40deg)'],
          }}
        />
      </Box>
      {/* Mac Noodle 1 */}
      <Box
        sx={{
          position: 'absolute',
          top: ['initial', '-30px'],
          left: ['-50px', 'initial'],
          transform: ['rotate(180deg)', 'none'],
        }}
      >
        <GatsbyImage
          alt=""
          image={macNoodleImg.gatsbyImageData}
          sx={{ width: '101px', height: '95px' }}
        />
      </Box>
      {/* Mac Noodle 2 */}
      <Box
        sx={{
          position: 'absolute',
          top: ['inital', '380px', '320px'],
          bottom: ['-80px', 'initial'],
          left: ['50px', '100px'],
          transform: ['rotate(175deg)', 'rotate(-85deg)'],
          zIndex: 3,
        }}
      >
        <GatsbyImage
          alt=""
          image={macNoodleImg.gatsbyImageData}
          sx={{ width: ['74px', '130px'], height: ['78px', '123px'] }}
        />
      </Box>
      {/* Mac Noodle 3 */}
      <Box
        sx={{
          position: 'absolute',
          bottom: ['130px', '-40px'],
          right: ['-20px', '33%'],
          transform: 'rotate(65deg)',
          zIndex: 3,
        }}
      >
        <GatsbyImage
          alt=""
          image={macNoodleImg.gatsbyImageData}
          sx={{ width: ['76px', '128px'], height: ['72px', '121px'] }}
        />
      </Box>
      <ImageAndTextSection
        cta={heroCallToAction}
        fluid={heroImage.fluid}
        headingText={heading}
        subHeadingText={subheading}
        containerStyle={{
          display: ['flex', 'none'],
          mt: ['0', '127px'],
          mb: ['22px'],
          my: 'auto',
          // color: 'schoolBusYellow',
          maxWidth: '37.5rem',
          width: '85vw',
          zIndex: 2,
          justifyContent: 'center',
        }}
        headingForwardSx={{
          fontSize: ['2.5rem', '1rem', '1rem'],
        }}
        subHeadingForwardSx={{
          fontSize: ['1rem', '1rem', '2.5rem'],
          fontWeight: 800,
        }}
        buttonStyle={{
          width: '100%',
          maxWidth: '320px',
          mt: '1rem',
          color: '#FFEE00',
          backgroundColor: 'override.press.button.back',
          ':hover': {
            color: 'override.press.button.hover.color',
            backgroundColor: '#FFEE00',
          },
        }}
      />
      <PressCopyAndQuotes section={pressSection} />
    </Flex>
  )
}

export default PressSection
