/** @jsx jsx */
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { jsx, useColorMode, useThemeUI, Box } from 'theme-ui'
import { WigglySectionDivider } from '../Generic/SectionDivider/WigglySectionDivider'
import { TestimonialCarousel } from '../Content/Section/TestimonialCarousel'
import Marquee from '../Generic/Marquee'
import ProductHighlightSection from './ProductHighlightSection'
import GeneralHighlightSection from './GeneralHighlightSection'
import VideoTestimonialsSection from './VideoTestimonials'
import { MobileWigglySectionDivider } from '~/components/Generic/SectionDivider/MobileWigglySectionDivider'
import PageHighlightsSection from '~/components/Home/PageHighlightsSection'
import NoodleBowlSection from '~/components/Home/NoodleBowlSection'
import ArticleHighlightSection from '~/components/Home/ArticleHighlightSection'
import RedeemGiftCardModal from '~/components/Home/RedeemGiftCardModal'
import { ProductGrid } from '~/components/Product/Grid/ProductGrid'
import HeroSection from '~/components/Home/HeroSection'
import PressSection from '~/components/Home/PressSection/index'
import useWindowSize from '~/hooks/components/use-window-size'

const HomePage = ({ recipientEmail, page }) => {
  const { productGrid, node_locale } = page
  const [giftModalOpen, setGiftModalOpen] = useState(false)
  const [_, setColorMode] = useColorMode('gf-p')
  const { theme } = useThemeUI()

  const windowSize = useWindowSize()
  const [isMobile, setIsMobile] = useState(null)

  useLayoutEffect(() => {
    setIsMobile(windowSize.width <= 768)
  }, [windowSize])

  const homePageButtonStyle = {
    color: theme.colors.governorBay,
    backgroundColor: theme.colors.schoolBusYellow,
  }

  useEffect(() => {
    setColorMode('gf-p')
  }, [setColorMode])

  useEffect(() => {
    const checkGiftCard = async () => {
      if (typeof window !== `undefined`) {
        // going to need to change this for intl stuff

        // const giftCardRoute = window.location.path.contains('redeemGiftCard')
        const giftCardRoute =
          window.location.pathname.includes('redeemGiftCard')
        if (giftCardRoute) {
          // TODO get this non scrolling background to work without a setTimeout
          setTimeout(() => {
            document.body.style.overflow = 'hidden'
          }, 2000)
          setGiftModalOpen(true)
        }
      }
    }
    checkGiftCard()
  }, [])

  return (
    // This -133 container is to compensate for the transparency of the NavBar
    <div
      sx={{
        position: 'relative',
        marginTop: '-133px',
        overflow: 'hidden',
        maxHeight: '100%',
      }}
    >
      {giftModalOpen && (
        <RedeemGiftCardModal
          setGiftModalOpen={setGiftModalOpen}
          recipientEmail={recipientEmail}
        />
      )}
      <HeroSection page={page} isMobile={isMobile} />
      <PressSection page={page} />
      <WigglySectionDivider
        invert
        topColor={theme.colors.schoolBusYellow}
        bottomColor={theme.colors.frenchPass}
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
          svg: {
            marginBottom: '-2px',
          },
        }}
        topColor={theme.colors.schoolBusYellow}
        bottomColor={theme.colors.frenchPass}
      />
      {/* <NoodleBowlSection page={page} /> */}
      {/* Removed wiggly section dividers as it has same background */}
      {/* <WigglySectionDivider
        topColor={theme.colors.governorBay}
        bottomColor={theme.colors.frenchPass}
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
          svg: {
            marginBottom: '-2px',
          },
        }}
        small
        invert
        topColor={theme.colors.governorBay}
        bottomColor={theme.colors.frenchPass}
      /> */}
      <ProductGrid data={productGrid} node_locale={node_locale} />
      <WigglySectionDivider
        invert
        topColor={theme.colors.frenchPass}
        bottomColor={theme.colors.robinsEggBlue}
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
          svg: {
            marginBottom: '2px',
          },
        }}
        small
        invert
        topColor={theme.colors.frenchPass}
        bottomColor={theme.colors.robinsEggBlue}
      />
      <Marquee page={page} />
      <WigglySectionDivider
        invert
        topColor={theme.colors.robinsEggBlue}
        bottomColor={theme.colors.governorBay}
        small
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
        }}
        small
        invert
        topColor={theme.colors.robinsEggBlue}
        bottomColor={theme.colors.governorBay}
      />
      <ProductHighlightSection page={page} />
      <WigglySectionDivider
        topColor={theme.colors.governorBay}
        bottomColor={theme.colors.schoolBusYellow}
        invert
        forwardSx={{
          display: ['none', 'flex'],
        }}
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          mt: -1,
          marginBottom: '-2px',
        }}
        topColor={theme.colors.governorBay}
        bottomColor={theme.colors.schoolBusYellow}
      />
      <TestimonialCarousel page={page} />
      <WigglySectionDivider
        topColor={theme.colors.schoolBusYellow}
        bottomColor={theme.colors.pink}
        forwardSx={{
          mt: -2,
        }}
        small
      />
      <PageHighlightsSection
        homePageButtonStyle={homePageButtonStyle}
        page={page}
      />

      <WigglySectionDivider
        topColor={theme.colors.pink}
        bottomColor={theme.colors.red}
        forwardSx={{
          display: ['none', 'flex'],
          mt: -2,
        }}
        invert
      />
      <WigglySectionDivider
        topColor={theme.colors.pink}
        bottomColor={theme.colors.red}
        forwardSx={{
          display: ['flex', 'none'],
          mt: -2,
        }}
        small
        invert
      />

      <VideoTestimonialsSection page={page} />
      <WigglySectionDivider
        topColor={theme.colors.red}
        bottomColor={theme.colors.schoolBusYellow}
        forwardSx={{
          display: ['none', 'flex'],
          mt: -2,
        }}
        small
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginTop: '-2px',
          svg: {
            marginBottom: '-2px',
          },
        }}
        topColor={theme.colors.red}
        bottomColor={theme.colors.schoolBusYellow}
      />
      <GeneralHighlightSection page={page} isMobile={isMobile} />
      <WigglySectionDivider
        topColor={theme.colors.schoolBusYellow}
        bottomColor={theme.colors.red}
        forwardSx={{
          display: ['none', 'flex'],
          mt: -1,
        }}
        small
      />
      <MobileWigglySectionDivider
        forwardSx={{
          position: 'relative',
          marginBottom: ['0px', '5px'],
          marginTop: '-2px',
          svg: {
            marginBottom: '-2px',
          },
        }}
        topColor={theme.colors.schoolBusYellow}
        bottomColor={theme.colors.red}
      />
      <Box
        sx={{
          backgroundColor: theme.colors.red,
        }}
      >
        <ArticleHighlightSection
          page={page}
          homePageButtonStyle={homePageButtonStyle}
        />
      </Box>
    </div>
  )
}

export default HomePage
